import { Component, OnInit } from '@angular/core';
import { Environment } from '@environments';
import { AssetsService } from '@shared-services/assets.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit
{
  constructor(public assetsService: AssetsService)
  {
    this.getSettings();
  }

  public ngOnInit(): void { }

  public getSettings(): void
  {
    this.assetsService.setBlobUrl = `${Environment.AzureStorageConfigAccountName}/${Environment.AzureStorageConfigAssetsContainer}`;
  }
}

