
import { Injectable } from "@angular/core";
import { AdditionalSettingsDataService } from "@core-data-services/additional-settings.data-service";
import { SettingsManager } from "@core-managers/settings.manager";
import { AdditionalSettings } from "@core-models/additional-settings.model";

@Injectable({ providedIn: 'root' })
export class DomainServiceDataResolver
{
  constructor(private service: AdditionalSettingsDataService,
    private settingsManager: SettingsManager) { }

  public resolve(): void
  {
    this.service.getShoppingCartConfig().subscribe({
      next: (response: AdditionalSettings.IShoppingCartConfigResponse) =>
      {
        if (response)
        {
          this.settingsManager.setItemsLimit = response.maxItems;
          this.settingsManager.setChatIconEnabled = response.chatEnabled;
          this.settingsManager.setWhatsappIconEnabled = response.whatsAppEnabled;
          this.settingsManager.setCreditCardGenericDomainEnabled = response.creditCardGenericDomainEnabled;
        }
      }
    });
  }
}
