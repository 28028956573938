import { Injectable } from '@angular/core';
import { SesionStorageConst } from '@core-constants/storage.const';
import { RouteHelper } from '@core-helpers/route.helper';
import { Coupon, GroupedShoppingCart, ShoppingCartPlusItem } from '@core-models/shopping-cart-plus.model';
import { UserData, UserInfo, UserTokenStorage } from '@shared-base/generic-clases.base';
import { LocalStorageService } from '@shared-services/local-storage.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class TokenManager
{
  constructor(public routeHelperService: RouteHelper,
    public localStorageService: LocalStorageService,
    public cookieService: CookieService) { }

  public signOut(): void
  {
    this.removeToken();
    this.removeRefreshToken();
    this.removeUser();
    this.removeCartInformation();
    this.removeSFData();
  }

  public saveUserAuthenticationData(data: UserData): void
  {
    this.saveToken(data.bearerToken);
    this.saveRefreshToken(data.refreshToken);
    this.saveCartCookie(data.cartId);
    this.saveUser(data.userInfoModel);
    this.saveUserAccessMethod(data.accessMethod);
    this.saveSFData(data.userInfoModel);
  }

  // "removeCartInformation" method deletes cart cookie, "removeAditionalCartInformation" method doesnt

  public removeAdditionalCartInformation(): void
  {
    this.removeCartItems();
    this.removeCartCount();
    this.removeCartTotal();
    this.removeCartVAT();
    this.removeCoupon();
    this.removeOrderId();
  }

  public removeCartInformation(): void
  {
    this.removeCartCookie();
    this.removeAdditionalCartInformation();
  }

  // ********************************************************************
  //#region Internal Base Methods
  // ********************************************************************

  private saveCookie(key: string, value: any, isPlainText: boolean = false): void
  {
    const valueToSet = isPlainText === true ? value : JSON.stringify(value);

    this.remove(key);

    this.cookieService.set(key, valueToSet, null, '', this.routeHelperService.domainName);
  }

  private remove(key: string): void
  {
    this.cookieService.delete(key, "/", this.routeHelperService.domainName);
    this.localStorageService.removeItem(key);
  }

  // #endregion


  // ********************************************************************
  //#region Token
  // ********************************************************************

  public saveToken(token: string): void
  {
    this.saveCookie(SesionStorageConst.TokenKey, token);
  }

  public getToken(): string | undefined
  {
    const res = this.cookieService.get(SesionStorageConst.TokenKey);
    return res && res != "" ? JSON.parse(res) : undefined;
  }

  private removeToken(): void
  {
    this.remove(SesionStorageConst.TokenKey);
  }
  // #endregion

  // ********************************************************************
  //#region Refresh Token
  // ********************************************************************

  public saveRefreshToken(refreshToken: string): void
  {
    this.saveCookie(SesionStorageConst.RefreshTokenKey, refreshToken);
  }

  public getRefreshToken(): string | undefined
  {

    const res = this.cookieService.get(SesionStorageConst.RefreshTokenKey);
    return res && res != "" ? JSON.parse(res) : undefined;
  }

  private removeRefreshToken(): void
  {
    this.remove(SesionStorageConst.RefreshTokenKey);
  }

  // #endregion

  // ********************************************************************
  //#region User
  // ********************************************************************

  public saveUser(user: UserInfo): void
  {
    this.saveCookie(SesionStorageConst.UserKey, user);
  }

  public getUser(): UserTokenStorage | undefined
  {

    const res = this.cookieService.get(SesionStorageConst.UserKey);
    return res && res != "" ? JSON.parse(res) : undefined;

  }

  private removeUser(): void
  {
    this.remove(SesionStorageConst.UserKey);
  }

  public saveUserId(userId: string): void
  {
    this.saveCookie(SesionStorageConst.UserId, userId);
  }

  private removeUserId(): void
  {
    this.remove(SesionStorageConst.UserId);
  }

  // #endregion

  // ********************************************************************
  //#region MSVC Authorization Token
  // ********************************************************************
  public saveMSVCToken(msvcToken: string): void
  {
    this.saveCookie(SesionStorageConst.MSVCToken, msvcToken);
  }

  private removeMSVCToken(): void
  {
    this.remove(SesionStorageConst.MSVCToken);
  }

  public getMSVCToken(): string | undefined
  {
    const res = this.cookieService.get(SesionStorageConst.MSVCToken);
    return res ? JSON.parse(res) : undefined;
  }
  // #endregion


  // ********************************************************************
  //#region CartId
  // ********************************************************************

  public saveCartCookie(cartCookie: string): void
  {
    this.saveCookie(SesionStorageConst.CartCookieKey, cartCookie);
  }

  public getCartCookie(): string
  {
    const res = this.cookieService.get(SesionStorageConst.CartCookieKey);
    return res && res != "" ? JSON.parse(res) : undefined;
  }

  private removeCartCookie(): void
  {
    this.remove(SesionStorageConst.CartCookieKey);
  }
  // #endregion

  // ********************************************************************
  //#region CartItems
  // ********************************************************************

  public saveCartItems(items: ShoppingCartPlusItem[]): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItems);
    this.localStorageService.setItem(SesionStorageConst.CartItems, JSON.stringify(items));
  }

  public saveGroupedCartItems(items: GroupedShoppingCart[]): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItemsGrouped);
    this.localStorageService.setItem(SesionStorageConst.CartItemsGrouped, JSON.stringify(items));
  }

  public getCartItems(): ShoppingCartPlusItem[] | undefined
  {
    const cartItems = this.localStorageService.getItem(SesionStorageConst.CartItems);
    return cartItems ? JSON.parse(cartItems) : undefined;
  }

  public getGroupedCartItems(): GroupedShoppingCart[] | undefined
  {
    const gropedCartItems = this.localStorageService.getItem(SesionStorageConst.CartItemsGrouped);
    return gropedCartItems ? JSON.parse(gropedCartItems) : undefined;
  }

  private removeCartItems(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItems);
    this.localStorageService.removeItem(SesionStorageConst.CartItemsGrouped);
  }

  // #endregion

  // ********************************************************************
  //#region CartCount
  // ********************************************************************

  public saveCartCount(count: number): void
  {
    this.removeCartCount();
    this.localStorageService.setItem(SesionStorageConst.CartItemsCount, JSON.stringify(count));
  }

  public getCartCount(): number | undefined
  {
    const cartCount = this.localStorageService.getItem(SesionStorageConst.CartItemsCount);
    return cartCount ? +JSON.parse(cartCount) : undefined;
  }

  private removeCartCount(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartItemsCount);
  }

  // #endregion

  // ********************************************************************
  //#region Total
  // ********************************************************************

  public saveCartTotal(total: number): void
  {
    this.removeCartTotal();
    this.localStorageService.setItem(SesionStorageConst.CartTotal, JSON.stringify(total));
  }

  public getCartTotal(): number | undefined
  {
    const total = this.localStorageService.getItem(SesionStorageConst.CartTotal);
    return total ? +JSON.parse(total) : undefined;
  }

  private removeCartTotal(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartTotal);
  }
  // #endregion

  // ********************************************************************
  //#region VAT
  // ********************************************************************

  public saveCartVAT(vat: number): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartVat);
    this.localStorageService.setItem(SesionStorageConst.CartVat, JSON.stringify(vat));
  }

  public getCartVAT(): number | undefined
  {
    const vat = this.localStorageService.getItem(SesionStorageConst.CartVat);
    return vat ? +JSON.parse(vat) : undefined;
  }

  private removeCartVAT(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartVat);
  }

  // #endregion

  // ********************************************************************
  //#region Coupon
  // ********************************************************************

  public saveCoupon(coupon: Coupon): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartCoupon);
    this.localStorageService.setItem(SesionStorageConst.CartCoupon, JSON.stringify(coupon));
  }

  public getCartCoupon(): Coupon | undefined
  {
    const coupon = this.localStorageService.getItem(SesionStorageConst.CartCoupon);
    return coupon ? JSON.parse(coupon) : undefined;
  }

  private removeCoupon(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.CartCoupon);
  }

  // #endregion

  // ********************************************************************
  //#region OrderId
  // ********************************************************************

  public saveOrderId(value: any): void
  {
    this.localStorageService.removeItem(SesionStorageConst.OrderId);
    this.localStorageService.setItem(SesionStorageConst.OrderId, JSON.stringify(value));
  }

  public getOrderId(): number | undefined
  {
    const orderId = this.localStorageService.getItem(SesionStorageConst.OrderId);
    return orderId ? JSON.parse(orderId) : undefined;
  }

  public removeOrderId(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.OrderId);
  }

  // #endregion

  // ********************************************************************
  //#region UserAccessMethod
  // ********************************************************************


  public saveUserAccessMethod(method: string): void
  {
    this.cookieService.delete(SesionStorageConst.UserAccessMethod);
    this.cookieService.set(SesionStorageConst.UserAccessMethod, JSON.stringify(method));
  }

  public getUserAccessMethod(): string | undefined
  {
    const method = this.cookieService.get(SesionStorageConst.UserAccessMethod);
    return method && method != "" ? JSON.parse(method) : undefined;
  }

  public removeUserAccessMethod(): void
  {
    this.cookieService.delete(SesionStorageConst.UserAccessMethod);
  }


  // #endregion


  // ********************************************************************
  //#region Hubspotutk cookie
  // ********************************************************************

  public getHubspotutk(): string
  {
    let result = '';
    const hubspotutkCookie = this.getHubspotutkCookie(SesionStorageConst.HubspotutkCookie);

    if (hubspotutkCookie !== undefined && hubspotutkCookie !== null)
    {
      result = hubspotutkCookie;
    }
    return result;
  }

  private getHubspotutkCookie(name: string): string
  {
    const ca: Array<string> = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string = "";

    for (let i: number = 0; i < caLen; i += 1)
    {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0)
      {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  // #endregion

  // ********************************************************************
  //#region Salesforce cookie
  // ********************************************************************

  public saveSFData(data: UserInfo): void
  {
    this.saveCookie(SesionStorageConst.UserId, data.id, true);
    this.saveCookie(SesionStorageConst.SFAccount, data.sfAccountId, true);
  }

  private removeSFData(): void
  {
    this.remove(SesionStorageConst.UserId);
    this.remove(SesionStorageConst.SFAccount);
  }

  // #endregion


  // ********************************************************************
  //#region Trx-id
  // ********************************************************************

  public saveAkkyTrxId(trxId: string): void
  {
    this.localStorageService.removeItem(SesionStorageConst.AkkyTrxId);
    this.localStorageService.setItem(SesionStorageConst.AkkyTrxId, JSON.stringify(trxId));
  }

  public getAkkyTrxId(): string | undefined
  {
    return this.localStorageService.getItem(SesionStorageConst.AkkyTrxId);
  }

  public removeAkkyTrxId(): void
  {
    this.localStorageService.removeItem(SesionStorageConst.AkkyTrxId);
  }
  // #endregion

}
